<template>
    <div class="w3-container" style="padding-top: 40px;padding-bottom:16px; margin-top:40px" v-bind:style="'height:'+  (screenHeight-40) +'px;'">
        <div class="w3-container" style="padding: 0px;">
            <div class="w3-card-4 w3-margin-bottom w3-round w3-col l6">
                <header class="w3-container w3-theme-d3 round-top-large">
                    <h5><i class="fa fa-windows"></i>&nbsp; Update password</h5>
                </header>
                <div class="w3-container">
                    <form class="w3-container" id="frmPass" v-on:submit.prevent="saveData" autocomplete="off">
                        <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label>Username :</label>
                            </div>
                            <div class="w3-col l9 m9 s12">
                                <input v-model="rec.username" class="w3-input w3-round-large" type="mail" placeholder="Enter Username" readonly>
                            </div>
                        </div>
                        <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label>Password lama :</label>
                            </div>
                            <div class="w3-col l9 m9 s12" style="position: relative;">
                                <input v-model="rec.passold" class="w3-input w3-round-large" :type="lihatold?'text':'password'" placeholder="Enter Password lama">
                                <button v-on:click="lihatold=!lihatold" type="button" class="w3-button w3-text-black w3-round-xlarge" style="position: absolute;top: 0;right: 0;"><i class="fa" :class="lihatold?'fa-eye':'fa-eye-slash'"></i></button>
                            </div>
                        </div>
                        <div class="w3-row" style="margin-top:5px;">
                            <div class="w3-col l3 m3 s12 w3-right-align w3-hide-small" style="padding-top: 5px;padding-right: 5px">
                                <label>Password baru :</label>
                            </div>
                            <div class="w3-col l9 m9 s12" style="position: relative;">
                                <input v-model="rec.passnew" class="w3-input w3-round-large" :type="lihatnew?'text':'password'" placeholder="Enter Password baru">
                                <button v-on:click="lihatnew=!lihatnew" type="button" class="w3-button w3-text-black w3-round-xlarge" style="position: absolute;top: 0;right: 0;"><i class="fa" :class="lihatnew?'fa-eye':'fa-eye-slash'"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            <footer class="w3-container w3-theme-d1"  style="margin-top: 15px;">
                <h6 class="w3-right">
                <button form="frmPass" class="w3-small w3-btn w3-ripple w3-white w3-round" type="submit"><i class="fa fa-save"></i> Save</button>
                <button type="button" class="w3-small w3-btn w3-ripple w3-red w3-round" v-on:click="toHome()"><i class="fa fa-close"></i> Close</button>
                </h6>
            </footer>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
import axios from "axios";

export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Profile',
    components: {
    },
    data() {
        return {
            screenHeight : 0,
            lihatold : false,
            lihatnew : false,
            rec: {
                username : localStorage.username,
                passold : '',
                passnew : ''
            }
        }
    },
    methods : {
        saveData(event){
            var self = this;
            self.rec['pfunction'] = 'updatepass';
            axios.post("admin/ApiAdmin.php",self.rec).then(function (response) {
                if(response.data.success == true){
                    self.$toast.success(response.data.msg);
                    self.rec = {
                        username : localStorage.username,
                        passold : '',
                        passnew : ''
                    }
                }else{
                    self.$toast.error(response.data.msg);
                }
            });
            event.preventDefault();
            return false;
        },
        toHome(){
            this.$router.push("/home");
        }
    },
    filters: {
    },
    watch: {
    },
    computed :{
    },
    mounted(){
        this.screenHeight = window.innerHeight;
    }
};
</script>